import React from 'react'
import { URL_SRV_RAIZ } from '../router/Url';

export const GetYearsOfData = ({empresa}) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_RAIZ+'getyearsdata',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'mode': 'no-cors'
      },
      body: JSON.stringify({
        empresa: empresa
      })
    })
    let responsito = await query.json();
    // console.log(responsito)
    if(responsito.data.success){
      resolve(responsito.data.body)
    }else{
      resolve([])
    }
  })
}
